<template>
  <div>
    <v-container>
    <lateral-dashboard />
      <h1>CrudProducts {{ nameCommerce }}</h1>

      <v-data-table
        loading-text="Cargando..."
        :loading="loading"
        :headers="headers"
        :items="products"
        sort-by="description"
        class="elevation-1"
        :search="search"
      >
        <v-toolbar flat color="white">
          <v-toolbar-title>Productos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialog = true" class="mx-4">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <template #top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar Productos"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-dialog v-model="dialog" max-width="500px">
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Nuevo Producto
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Nombre Producto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.description"
                          label="Descripcion"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.price"
                          label="Precio"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.stok"
                          label="Stok"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox
                          v-model="editedItem.active"
                          label="Activo"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="80%">
              <v-card>
                <v-card-title class="text-h5"
                  >¿Estas seguro que deseas eliminar este
                  producto?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="error" text @click="deleteItemConfirm"
                    >Eliminar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template #no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
import lateralDashboard from './../components/lateral-dashboard.vue'
export default {
  name: "CrudProducts",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: "",
    loading: false,
    headers: [
      { text: "Nombre", value: "name", sortable: true },
      { text: "Descripción", value: "description" },
      { text: "Precio", value: "price" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    prueba: [],
    products: [],
    editedIndex: -1,
    snackbar: false,
    editedItem: {
      name: "",
      description: 0,
      price: 0,
      stok: 0,
      active: false,
    },
    defaultItem: {
      name: "",
      description: 0,
      price: 0,
      stok: 0,
      active: false,
    },
  }),
  components:{
        lateralDashboard
    },
  computed: {
    ...mapState(["nameCommerce", "apiURL"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Producto" : "Editar Producto";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    initialize() {
      this.loading = true;
      axios
        .get(`${this.apiURL}/products/commerce/${this.nameCommerce}`)
        .then((response) => {
          this.products = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.products.splice(this.editedIndex, 1);
      axios
        .delete(`${this.apiURL}/delete/product/${this.editedItem._id}`)
        .then((res) => {
          this.snackbar = res.data;
        })
        .catch((err) => {
          console.log(err, "error");
        });
      await this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.products[this.editedIndex], this.editedItem);
        axios
          .put(
            `${this.apiURL}/update/product/${this.editedItem._id}`,
            this.editedItem
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err, "error");
          });
      } else {
        this.products.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>